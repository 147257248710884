<template>
    <div class="intro-steps">
        <h3 class="intro-steps__header">{{ content.header }}</h3>
        <div class="intro-steps__content">
            <div class="intro-steps__image-wrapper">
                <img
                    :src="image"
                    class="intro-steps__image"
                    :alt="$t('Student raising hand')"
                >
            </div>
            <div class="intro-steps__text">
                <ul class="intro-steps__list">
                    <li v-for="(step, idx) in content.steps" :key="`step-${idx}`" class="intro-steps__step">
                        <CircleNumber :number="idx + 1" class="intro-steps__number" />
                        <span>
                            <span class="intro-steps__step-name">{{ step.name }}</span>
                            {{ step.description }}
                            <span v-if="step.note" class="intro-steps__step-note">{{ step.note }}</span>
                        </span>
                    </li>
                </ul>
                <NebulaButton
                    class="intro-steps__button"
                    :text="content.actionButtonText"
                    :link="content.actionButtonLink"
                    routerLink
                    icon-left="circle-play"
                    data-click-type="View Connector Dashboard: Connector"
                    :data-click-name="content.actionButtonText"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CircleNumber from '@/components/shared/tokens/CircleNumber.vue';
import { NebulaButton } from '@discoveryedu/nebula-components';
import { introSteps } from '@/content/intro';

const image = require('@/assets/stock/student-raised-hand.png');

export default {
    name: 'IntroSteps',
    components: {
        CircleNumber,
        NebulaButton,
    },
    data() {
        return {
            image,
        };
    },
    computed: {
        appMode() {
            return this.$store.getters.appMode;
        },
        content() {
            return introSteps[this.appMode?.toLowerCase()];
        },
    },
};
</script>

<style lang="stylus">
.intro-steps {
    list-style-type: none;
    padding: $nebula-space-3x;

    &__button {
        display: flex;
        justify-content: center;
        @media (max-width: $nebula-breakpoints-tablet-portrait - 1) {
            width: 100%;
        }
    }
    &__text {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            margin-inline-start: $nebula-space-4x;
        }
    }
    &__header {
        card-internal-title();
        margin: 0 $nebula-space-5x $nebula-space-2x 0;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            margin: 0 0 $nebula-space-2x;
        }
    }
    &__number {
        margin-inline-end: $nebula-space-1x;
        flex-shrink: 0;
    }
    &__list {
        list-style-type: none;
        margin-inline-start: 0;
        padding-inline-start: 0;
    }
    &__step {
        display: flex;
        margin-block-end: $nebula-space-3x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            align-items: center;
        }
    }
    &__step-name {
        font-weight: 700;
    }
    &__step-note {
        font-style: italic;
        margin-left: $nebula-space-quarter;
    }
    &__image-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            width: unset;
        }
    }
    &__image {
        height: 245px;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            height: 150px;
        }
        @media (min-width: $nebula-breakpoints-desktop) {
            height: 267px;
        }
    }
    &__content {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            align-items: center;
        }
    }
}
</style>
