<template>
    <div class="calendar-tile">
        <div class="calendar-tile__character">
            <img
                :src="calendarImage"
                class="calendar-tile__character-image"
                :alt="$t('Disco character holding a calendar')"
            >
        </div>
        <div v-if="date" class="calendar-tile__calendar">
            <div class="calendar-tile__week">{{ formattedDate.weekday }}</div>
            <div class="calendar-tile__month">{{ formattedDate.month }}</div>
            <div class="calendar-tile__day">{{ formattedDate.day }}</div>
        </div>
    </div>
</template>

<script>

import formatDate from '@/mixins/formatDate';

const calendarImage = require('@/assets/img-disco-dog-calendar.png');

export default {
    name: 'CalendarTile',
    mixins: [formatDate],
    computed: {
        formattedDate() {
            const formattedDate = this.formatDate(this.date, 'stacked');
            const [weekday, month, day] = formattedDate.split(' ');
            return {
                weekday,
                month,
                day,
            };
        },
    },
    data() {
        return {
            calendarImage,
        };
    },
    props: {
        date: {
            type: [Number, Boolean],
        },
    },
};
</script>

<style lang="stylus">
.calendar-tile {
    display: flex;
    padding-top: $nebula-space-1x;

    &__character {
        position: relative;

        &-image {
            height: 219px;
            width: 217px;
        }
    }

    &__calendar {
        align-items: center;
        align-self: start;
        background-color: $nebula-color-platform-white;
        border-radius: $nebula-border-radius-small;
        border: 1px solid $nebula-color-platform-interface-400;
        display: flex;
        flex-direction: column;
        line-height: 1;
        margin-inline-start: -($nebula-space-7x);
        margin-block-start: $nebula-space-2x;
        padding: $nebula-space-3x $nebula-space-5x;
        text-align: center;
        text-transform: uppercase;
    }

    &__week {
        font-size: $nebula-font-size-card-title;
        font-weight: 700;
    }

    &__month {
        margin-block-start: $nebula-space-1x;
    }

    &__day {
        font-size: $nebula-font-size-header-level-3;
        font-weight: 700;
        margin-block-start: $nebula-space-1x;
    }
}
</style>
