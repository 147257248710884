<template>
    <PageWrapper
        class="employee-dashboard-container"
        :contained="false"
    >
        <NebulaGridRow gutter>
            <Intro v-bind="{ profileAttrs }">
                <ProfileCard
                    v-if="flags['intro-steps']"
                    class="dashboard-intro__profile-card"
                    :data="employeeData"
                    selfView
                />
                <EmployeeProfile
                    v-else
                    :data="employeeData"
                    selfView
                />
            </Intro>
            <NebulaGridBlock
                v-if="!flags['calendar-removed']"
                class="employee-dashboard__availability-panel"
                :columns="6"
                :mdSize="8"
            >
                <Availability
                    :showColumn="false"
                />
            </NebulaGridBlock>
        </NebulaGridRow>
        <PageSection v-if="showUpcomingRequest" type="emphasized" padded>
            <NebulaGridRow>
                <NebulaGridBlock
                    :columns="12"
                >
                    <UpcomingRequest @hide-section="hideUpcomingRequest" />
                </NebulaGridBlock>
            </NebulaGridRow>
        </PageSection>
        <PageSection v-if="loading.connects">
            <NebulaGridRow type="flex">
                <NebulaColumn :columns="12">
                    <GrayBoxLoader card />
                </NebulaColumn>
            </NebulaGridRow>
        </PageSection>
        <PageSection
            title="My Connects"
            v-if="connects && connects.length > 0"
            :viewAll="{ show: true, link: '/connect-directory?1=my-connects' }"
        >
            <NebulaGridRow type="flex">
                <NebulaColumn :columns="12">
                    <RequestDirectoryTable
                        class="request-directory__list-view"
                        :data="connects"
                        mode="dashboard"
                    />
                </NebulaColumn>
            </NebulaGridRow>
        </PageSection>
        <PageSection
            title="Recommended For You"
            v-if="recommended && recommended.length > 0"
            :viewAll="{ show: true, link: '/connect-directory?1=open-requests' }"
        >
            <NebulaGridRow type="flex">
                <NebulaColumn :columns="12">
                    <NebulaGridRow gutter>
                        <NebulaGridBlock v-for="(request, idx) in recommended" :key="`recommended-${idx}`" :columns="4">
                            <RequestCard :data="request" />
                        </NebulaGridBlock>
                    </NebulaGridRow>
                </NebulaColumn>
            </NebulaGridRow>
        </PageSection>
        <ResourcesRow title="My Resources" topic="evergreen-connector" />
        <OrgResourcesRow
            v-if="organizationId"
            :category="organizationId"
            :viewAll="{ show: true, link: `/organization/${organizationId}/content` }"
        />
    </PageWrapper>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';
import PageSection from '@/components/shared/layout/PageSection.vue';
import EmployeeProfile from '@/components/dashboard/EmployeeProfile.vue';
import ProfileCard from '@/components/dashboard/ProfileCard.vue';

import Intro from '@/components/dashboard/Intro.vue';
import UpcomingRequest from '@/components/dashboard/UpcomingRequest.vue';
import Availability from '@/components/Profile/Availability.vue';
import OrgResourcesRow from '@/components/Profile/shared/OrgResourcesRow.vue';
import ResourcesRow from '@/components/resources/ResourcesRow.vue';
import RequestDirectoryTable from '@/components/shared/RequestDirectoryTable.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import RequestCard from '@/components/shared/cards/RequestCard.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

import {
    inXdays,
    timestampNow,
} from '@/data/placeholder/date';
import createPayload from '@/mixins/data/createPayload';
import scrollToTop from '@/mixins/scrollToTop';
import application from '@/mixins/requests/application';

// import { futureStatuses } from '@/constants';

export default {
    name: 'EmployeeDashboard',
    components: {
        GrayBoxLoader,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        PageSection,
        PageWrapper,
        EmployeeProfile,
        ProfileCard,
        UpcomingRequest,
        Availability,
        OrgResourcesRow,
        ResourcesRow,
        RequestDirectoryTable,
        RequestCard,
        Intro,
    },
    mixins: [createPayload, scrollToTop, application],
    provide: {
        domainType: 'employee',
        pageType: 'dashboard',
    },
    computed: {
        employeeData() {
            const { data } = this.$store.state.user;
            const { organization } = this.$store.state.app;
            return { ...data, ...{ organization } };
        },
        resources() {
            return this.$store.state.page.assets;
        },
        organizationId() {
            return this.$store.state.app.organization.organizationId;
        },
        flags() {
            return this.$store.getters.flags;
        },
        connects() {
            return this.$store.state.page.requests.filter((each) => each.type);
        },
        recommended() {
            return this.$store.state.page.recommended.filter((each) => each.type);
        },
        profileAttrs() {
            let attrs = {
                columns: 6,
                mdSize: 8,
            };
            if (this.flags['calendar-removed']) {
                attrs = {
                    columns: 8,
                    offset: 2,
                    mdOffset: 0,
                };
            }

            return attrs;
        },
    },
    data() {
        return {
            loading: {
                connects: true,
            },
            showUpcomingRequest: true,
            hasRecentRequestData: true,
            resourceCards: [
                {
                    assetId: 'd6c7dc0c-ac97-4fda-bd6b-99cfaa4bedbf',
                },
                {
                    assetId: 'e5c2081c-fda7-47a7-8b0c-fa785571dd83',
                },
                {
                    assetId: '3def4831-5b55-4c91-9e0a-a3ab233338fd',
                },
                {
                    assetId: 'c80977f5-efb5-4ac5-b27a-179bbf0c1ed5',
                },
            ],
        };
    },
    methods: {
        async getRecommendedRequests() {
            const requestStartDate = [timestampNow()];
            const requestEndDate = [inXdays(365)];

            const rawConnectParams = [
                ['limit', 3],
                ['startDate', requestStartDate],
                ['endDate', requestEndDate],
                ['employee_status', 'open'],
                ['sort', 'startDate'],
            ];

            const connectParams = new URLSearchParams(rawConnectParams).toString();
            const connectPayload = await this.createPayload({ params: connectParams, key: 'recommended' });
            await this.$store.dispatch('getRequests', connectPayload);
        },
        async getMyRequests() {
            const requestStartDate = [timestampNow()];
            const requestEndDate = [inXdays(365)];

            const rawConnectParams = [
                ['limit', 5],
                ['startDate', requestStartDate],
                ['endDate', requestEndDate],
                ['employee_status', 'pending'],
                ['employee_status', 'direct'],
                ['employee_status', 'assigned'],
                ['sort', 'startDate'],
            ];

            const connectParams = new URLSearchParams(rawConnectParams).toString();
            const connectPayload = await this.createPayload({ params: connectParams, employeeStatus: true });
            return this.$store.dispatch('getRequests', connectPayload);
        },
        hideUpcomingRequest() {
            this.showUpcomingRequest = false;
        },
    },
    async mounted() {
        this.scrollToTop();
        this.getApplications();

        // const recommendedPayload = await this.createPayload();
        // this.$store.dispatch('getRecommended', recommendedPayload);

        await Promise.all([
            this.getMyRequests(),
            this.getRecommendedRequests(),
        ]);
        this.loading.connects = false;
    },
};
</script>

<style lang="stylus">
.employee-dashboard {
    &__availability-panel {
        card-base();
        padding: $nebula-space-3x;
    }
    &__profile-card {
        overflow: hidden;
    }
}
</style>
