const statCardsMap = {
    active_employees_count: {
        title: 'Active Connectors',
        popover: {
            description: 'Total active employees on this platform from your organization in the past 30 days.',
            linkDisplay: 'Manage Users',
            link: '/user-management',
        },
        icon: 'activity',
    },
    total_requests: {
        title: 'Completed Connects',
        popover: {
            description: 'Total completed Connects with Connectors in your organization.',
        },
        icon: 'clipboard',
    },
    total_scheduled_time: {
        title: 'Hours Volunteered',
        popover: {
            description: 'Total hours volunteered via completed Connects by Connectors within your organization.',
        },
        icon: 'clock',
    },
    total_students_count: {
        title: 'Students Reached',
        popover: {
            description: 'Total students reached via completed Connects with Connectors in your organization.',
        },
        icon: 'classroom',
    },
};

const deAdminStatsMap = {
    active_employees_count: {
        title: 'Active Connectors',
        popover: {
            description: 'Total active employees on this platform in the past 30 days.',
            linkDisplay: 'Manage Users',
            link: '/user-management',
        },
        icon: 'activity',
    },
    total_requests: {
        title: 'Scheduled Connects',
        popover: {
            description: 'Total scheduled Connects with Connectors.',
        },
        icon: 'clipboard',
    },
    total_scheduled_time: {
        title: 'Hours Volunteered',
        popover: {
            description: 'Total hours volunteered via completed Connects by Connectors',
        },
        icon: 'clock',
    },
    total_students_count: {
        title: 'Students Reached',
        popover: {
            description: 'Total students reached via completed Connects with Connectors.',
        },
        icon: 'classroom',
    },
    inactive_employees: {
        title: 'Inactive Connectors',
        icon: 'activity',
    },
    total_employees: {
        title: 'Total Connectors',
        icon: 'activity',
    },
    total_active_educators: {
        title: 'Total Active Educators',
        icon: 'activity',
    },
};

export default { statCardsMap, deAdminStatsMap };
