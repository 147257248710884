<template>
    <span class="circle-number__container">
        <NebulaIcon v-if="icon" class="stepper__icon" :symbol-id="icon" size="s" />
        <span v-else class="circle-number__glyph">
            {{ number }}
        </span>
    </span>
</template>

<script>
export default {
    name: 'CircleNumber',
    props: {
        icon: String,
        number: Number,
    },
};
</script>

<style lang="stylus">
$circle-number-size = 28px;

.circle-number {
    &__container {
        nebula-text-caption();
        align-items: center;
        border: 2px solid $nebula-color-platform-interactive-800;
        border-radius: 50%;
        color: $nebula-color-platform-neutral-900;
        display: flex;
        height: $circle-number-size;
        justify-content: center;
        transition: $nebula-transition-default;
        width: $circle-number-size;

        @media (min-width: $nebula-breakpoints-tablet-landscape) {
            margin-right: $nebula-space-1x;
        }
    }
    &__glyph {
        display: block;
        font-weight: 600;
        line-height: 0;
        color: $nebula-color-platform-interactive-800;
    }
}
</style>
