<template>
    <EmployeeDashboard v-if="appMode == 'employee'" />
    <EducatorDashboard v-else-if="appMode == 'educator'" />
    <EmployerDashboard v-else />
</template>

<script>
import EmployeeDashboard from '@/views/EmployeeDashboard.vue';
import EmployerDashboard from '@/views/EmployerDashboard.vue';
import EducatorDashboard from '@/views/educators/Dashboard.vue';

export default {
    name: 'Dashboard',
    components: {
        EmployeeDashboard,
        EmployerDashboard,
        EducatorDashboard,
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
    },
};
</script>
