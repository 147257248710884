<template>
    <div>
        <PageHeader class="intro-message__title" :title="welcomeMessage">
            <template v-slot:actions v-if="appMode === roles.EDUCATOR_ROLE">
                <NebulaButton
                    icon-left="mail-send"
                    text="Create a New Request"
                    routerLink
                    link="/request/new"
                    data-click-type="Dashboard: Educator"
                    data-click-name="Create a New Request"
                />
            </template>
        </PageHeader>
        <div>
            <h2 class="intro-message__subheader">{{ content.title }}</h2>
            <p class="intro-message__description">
                {{ content.description }}
            </p>
        </div>
    </div>
</template>

<script>
import { NebulaButton } from '@discoveryedu/nebula-components';

import PageHeader from '@/components/shared/PageHeader.vue';
import { roles } from '@/constants';
import { introMessage } from '@/content/intro';

export default {
    name: 'IntroMessage',
    components: {
        PageHeader,
        NebulaButton,
    },
    data() {
        return {
            roles,
        };
    },
    computed: {
        appMode() {
            return this.$store.getters.appMode;
        },
        content() {
            return introMessage[this.appMode?.toLowerCase()];
        },
        firstName() {
            const { preferredName, firstName } = this.$store.state.user.data;
            return preferredName || firstName;
        },
        welcomeMessage() {
            if (this.firstName) {
                return `Welcome, ${this.firstName}!`;
            }

            return 'Welcome!';
        },
    },
};
</script>

<style lang="stylus">
.intro-message {
    &__title {
        h1 {
            font-size: $nebula-font-size-header-level-3;
            font-weight: 400;
        }
        &.page-heading__container {
            margin-block-start: 0;
            margin-block-end: $nebula-space-3x;
        }
    }
    &__subheader {
        font-size: $nebula-font-size-header-level-5;
        margin: 0 0 $nebula-space-1x;
    }
    &__description {
        nebula-body-1();
        margin: 0 0 $nebula-space-3x;
    }
}
</style>
