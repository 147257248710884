<template>
    <NebulaGridBlock :columns="12" v-if="flags['intro-steps']">
        <NebulaPageWrapper contained>
            <NebulaGridRow gutter>
                <NebulaGridBlock :columns="12">
                    <IntroMessage />
                </NebulaGridBlock>
            </NebulaGridRow>
            <NebulaGridRow gutter class="dashboard-intro__bottom-margin">
                <NebulaGridBlock
                    :columns="9"
                    :mdSize="8"
                    class="dashboard-intro__card"
                >
                    <IntroSteps />
                </NebulaGridBlock>
                <NebulaGridBlock
                    :columns="3"
                    :mdSize="8"
                    class="dashboard-intro__card dashboard-intro__card--spaced dashboard-intro__card--overflow"
                >
                    <BlobBackground>
                        <slot />
                    </BlobBackground>
                </NebulaGridBlock>
            </NebulaGridRow>
        </NebulaPageWrapper>
    </NebulaGridBlock>
    <NebulaGridBlock
        v-else
        v-bind="profileAttrs"
        class="dashboard-intro__card"
    >
        <slot />
    </NebulaGridBlock>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';

import BlobBackground from '@/components/shared/brand/BlobBackground.vue';
import IntroSteps from '@/components/dashboard/Connector/IntroSteps.vue';
import IntroMessage from '@/components/dashboard/Connector/IntroMessage.vue';

export default {
    name: 'DashboardIntro',
    props: {
        profileAttrs: Object,
    },
    components: {
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        IntroSteps,
        IntroMessage,
        BlobBackground,
    },
    computed: {
        flags() {
            return this.$store.getters.flags;
        },
    },
};
</script>

<style lang="stylus">
.dashboard-intro {
    &__card {
        card-base();
        background: $nebula-color-white;
        &--spaced {
            @media (min-width: $nebula-breakpoints-tablet-portrait) {
                margin-inline-start: $nebula-space-1x;
            }
        }
        &--overflow {
            overflow: visible;
            align-self: flex-start;
        }
    }

    &__bottom-margin {
        margin-block-end: 80px;
    }
}
</style>
