<template>
    <div>
        <ProfileBanner />
        <div class="profile-panel__content">
            <ProfileAvatar
                class="profile-panel__user-avatar"
                :image="data.avatarUrl"
                :border="true"
                :name="data.firstName + ' ' + data.lastName"
                size="xl"
            />
            <NebulaButton
                v-if="!preview && selfView"
                icon-left="edit"
                link="/profile-setup"
                text="Edit Profile"
                type="flat"
            />
            <div>
                <h2 class="profile-panel__name">
                    {{ displayName }} {{ data.lastName }}
                    <span
                        v-if="data.pronouns"
                        class="profile-panel__pronouns"
                    >
                        ({{ $t(data.pronouns) }})
                    </span>
                </h2>
                <p class="profile-panel__title">
                    {{ title }}
                </p>
                <p class="profile-panel__school" v-if="school">
                    {{ school }}
                </p>
                <p class="employee-profile__location">
                    {{ location }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { NebulaButton } from '@discoveryedu/nebula-components';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import ProfileBanner from '@/components/shared/Profile/ProfileBanner.vue';
import { roles, ROLE_DISPLAY_MAP } from '@/constants';

export default {
    name: 'ProfilePanel',
    components: {
        NebulaButton,
        ProfileAvatar,
        ProfileBanner,
    },
    props: {
        data: Object,
        preview: Boolean,
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
        selfView() {
            const { id, employeeId } = this.$store.state.user.data;
            // TODO change all users to just use id
            const currentUser = id || employeeId;
            return this.data.id === currentUser;
        },
        displayName() {
            return this.data.preferredName || this.data.firstName;
        },
        location() {
            let city;
            let state;
            let spacer = ', ';

            if (this.data.site) {
                const { site_city: siteCity, site_state: siteState } = this.data.site;
                city = siteCity;
                state = siteState;
            } else {
                city = this.data.city;
                state = this.data.state;
            }

            if (!city || !state) {
                spacer = '';
            }

            return `${city || ''}${spacer}${state || ''}`;
        },
        title() {
            if (this.appMode === roles.EDUCATOR_ROLE) {
                return ROLE_DISPLAY_MAP[roles.EDUCATOR_ROLE];
            }
            return this.data.title;
        },
        school() {
            return this.data.site ? this.data.site.site_name : null;
        },
    },
};
</script>

<style lang="stylus">
.profile-panel {
    &__top-block {
        width: 100%;
    }
    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: $nebula-space-2x;
        padding: 0 $nebula-space-3x $nebula-space-3x;
    }

    &__user-avatar {
        --careers-avatar-border-color: var(--nebula-color-white);

        margin-block-start: calc(calc(136px / 2) * -1); // This element is 136px tall with the xl size and a 3px border.
    }

    &__name, &__title, &__school {
        text-align: center;
    }

    &__school, &__title {
        nebula-text-body-2();
        nebula-text-semibold();

        margin: 0;
    }

    &__name {
        nebula-text-responsive-h6();

        margin: 0;
    }

    &__pronouns {
        nebula-text-caption();

        color: $nebula-color-platform-neutral-700;
    }

    &__school {
        color: $nebula-color-platform-neutral-700;
    }

    &__title {
        color: $nebula-color-platform-neutral-900;
    }
}
</style>
