<template>
    <div class="upcoming-request">
        <GrayBoxLoader v-if="loading" card />
        <div
            v-else-if="hasRequestData"
            class="upcoming-request__content"
        >
            <component :is="getComponent">
                <template v-slot:sectionTitle>
                    <NebulaTitleBlock
                        class="upcoming-request__title"
                        :title="$t('Upcoming Connect')"
                        type="section"
                    />
                </template>
                <template v-slot:image>
                    <CalendarTile :date="isConnector && request.startDateTime" />
                </template>
                <template v-slot:title>
                    <div>
                        <h2 class="upcoming-request__type">{{ typeDisplay }}</h2>
                        <h3 class="upcoming-request__title">{{ request.title }}</h3>
                    </div>
                </template>
                <template v-slot:details>
                    <h3 class="upcoming-request__subhead">Details</h3>
                    <DetailBlock :data="request" simple />
                </template>
                <template v-slot:joinLink>
                    <div
                        v-if="videoFF"
                        class="upcoming-request__join-action"
                    >
                        <p class="upcoming-request__time-warning">{{ timeMessage }}</p>
                        <NebulaButton
                            class="upcoming-request__join-button"
                            icon-left="video"
                            :link="joinLink"
                            :size="m"
                            :text="$t('Join call')"
                            :aria-label="$t('Join call')"
                            type="fill"
                        />
                    </div>
                </template>
                <template v-slot:participant>
                    <ParticipantCard
                        v-bind="{request, participantType}"
                        class="upcoming-request__participant"
                    />
                </template>
            </component>
        </div>
        <EmptyState
            v-else
            image="calendar"
            class="upcoming-request--empty"
            sectionTitle="Upcoming Connects"
            :button="{
                text: 'Explore Connect Directory',
                link: '/connect-directory',
                iconLeft: 'clipboard',
            }"
            :showButton="$store.getters.requestDirectoryFF"
            icon="clipboard"
            title="You have no upcoming Connects."
            :description="emptyStateText"
        />
    </div>
</template>

<script>
import { NebulaButton, NebulaTitleBlock } from '@discoveryedu/nebula-components';
import nebulaBreakpoints from '@/mixins/nebulaBreakpoints';
import timeRemaining from '@/utils/timeRemaining';
import formatDate from '@/mixins/formatDate';
import DetailBlock from '@/components/dashboard/DetailBlock.vue';
import EmptyState from '@/components/shared/layout/EmptyState.vue';
import ParticipantCard from '@/components/shared/ParticipantCard.vue';
import createPayload from '@/mixins/data/createPayload';
import { typeOptions } from '@/data/formData';
import {
    roles,
    partnerMap,
    MEMBER_ROLES,
} from '@/constants';
import UpcomingRequestConnector from '@/components/shared/requests/UpcomingRequestConnector.vue';
import UpcomingRequestEducator from '@/components/shared/requests/UpcomingRequestEducator.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import {
    timestampNow,
} from '@/data/placeholder/date';

import CalendarTile from './CalendarTile.vue';

const calendarImage = require('@/assets/img-disco-dog-calendar.png');

export default {
    name: 'UpcomingRequest',
    components: {
        CalendarTile,
        DetailBlock,
        NebulaButton,
        NebulaTitleBlock,
        EmptyState,
        ParticipantCard,
        UpcomingRequestConnector,
        GrayBoxLoader,
    },
    mixins: [
        nebulaBreakpoints,
        formatDate,
        createPayload,
    ],
    data() {
        return {
            loading: true,
            calendarImage,
            request: {},
        };
    },
    computed: {
        joinLink() {
            // TODO I assume this will be different, but make that link here (or change to func)
            return '/message';
        },
        timeMessage() {
            return `Starts in ${timeRemaining(this.request.time)}`;
        },
        hasRequestData() {
            return this.request && Object.keys(this.request).length;
        },
        typeDisplay() {
            if (!this.request.type) {
                return '';
            }
            const typeObj = typeOptions.find((each) => each.value === this.request.type);
            return typeObj.text;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        isConnector() {
            return this.appMode === roles.EMPLOYEE_ROLE;
        },
        showCalendarTile() {
            return this.appMode === roles.EMPLOYEE_ROLE;
        },
        getComponent() {
            if (this.isConnector) {
                return UpcomingRequestConnector;
            }
            return UpcomingRequestEducator;
        },
        emptyStateText() {
            if (this.appMode === roles.EDUCATOR_ROLE) {
                return 'When you do, you\'ll see them here. Click “Create a New Request” above to get started.';
            }
            return 'When you do, you\'ll see them here.';
        },
        participantType() {
            if (!MEMBER_ROLES.includes(this.appMode)) {
                return null;
            }
            return partnerMap[this.appMode][0];
        },
        // feature flags
        videoFF() {
            return this.$store.getters.videoFF;
        },
    },
    props: {
        date: {
            type: Date,
        },
        user: {
            type: Object,
        },
    },
    async mounted() {
        const requestStartDate = [timestampNow()];

        const params = new URLSearchParams([
            ['limit', 1],
            ['status', 'scheduled'],
            ['sort', 'startDate'],
            ['order', 'ASC'],
            ['startDate', requestStartDate],
        ]).toString();

        const payload = await this.createPayload({ params, key: 'upcoming' });
        await this.$store.dispatch('getRequests', payload);

        [this.request] = this.$store.state.page.upcoming;
        this.loading = false;
    },
};
</script>

<style lang='stylus'>
.upcoming-request {
    &--empty {
        flex: 1;
        display: flex;
        flex-direction: column;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            flex-direction: row;
        }
        .career-empty-state {
            flex: 3;
        }
    }

    &__title {
        font-weight: 400;
        align-items: flex-start;
    }

    &__type {
        margin: 0;
    }

    &__image-block {
        padding-block: $nebula-space-2x;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        card-internal-title();
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @media (min-width: $nebula-breakpoints-desktop) {
            flex-direction: row;
            gap: $nebula-space-5x;
        }
    }

    &__intro {
        display: none;

        @media (min-width: $nebula-breakpoints-desktop) {
            display: block;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: $nebula-space-2x;
    }

    &__header {
        @media (min-width: $nebula-breakpoints-desktop) {
            margin-top: $nebula-space-2x;
        }
    }

    &__name {
        font-size: 28px;
        margin: 0;
    }

    &__topic {
        margin: 0;
        font-weight: 400;
    }

    &__badge {
        margin-block-start: $nebula-space-1x;
    }

    &__subhead {
        nebula-text-responsive-h6();

        margin: 0 0 $nebula-space-1x;
    }

    &__actions {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        gap: $nebula-space-3x;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            flex-basis: 50%;
        }

        @media (min-width: $nebula-breakpoints-desktop) {
            display: flex;
            flex-basis: 25%;
            justify-content: flex-end;
        }
    }

    &__join-action {
        align-items: flex-start;
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        gap: $nebula-space-2x;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            align-items: center;
            align-self: flex-end;
            flex-basis: auto;
            flex-direction: row;
            gap: $nebula-space-3x;
        }
    }

    &__time-warning {
        color: $nebula-color-feedback-success-700;
        margin: 0;
    }

    &__join-button {
        width: 100%;
        justify-content: center;
        margin-block-end: $nebula-space-3x;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            width: auto;
            margin: 0;
        }
    }
}
</style>
