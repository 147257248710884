<template>
    <li
        class="stat-card"
    >
        <div class="stat-card__content">
            <div class="stat-card__header">
                <h3 class="stat-card__title">{{ data.title }}</h3>
                <NebulaPopover
                    v-if="data.popover"
                    :ariaLabel="`Additional information about` + data.title"
                    :id="`popover-` + data.id"
                    placement="top"
                    hideCloseButton
                    class="stat-card__popover"
                >
                    <template v-slot:content>
                        <div class="stat-card__popover-content">
                            <p class="stat-card__popover-text">
                                {{ data.popover.description }}
                                <a
                                    v-if="data.popover.link"
                                    :href="data.popover.link"
                                    class="stat-card__popover-link"
                                >
                                    {{ data.popover.linkDisplay }}
                                </a>
                            </p>
                        </div>
                    </template>
                    <template v-slot:trigger="{ togglePopover, popoverOpen }">
                        <NebulaIconButton
                            :aria-expanded="popoverOpen && 'true' || 'false'"
                            aria-haspopup="true"
                            class="stat-card__trigger"
                            @click="togglePopover"
                            :id="`nebula-popover-trigger__popover-` + data.id"
                            icon="information"
                            :size="isNebulaBreakpointsTabletPortrait ? 'm' : 's'"
                            type="flat"
                        />
                    </template>
                </NebulaPopover>
            </div>
            <div class="stat-card__body">
                <div class="stat-card__metadata">
                    <NebulaIcon
                        v-if="isNebulaBreakpointsTabletPortrait"
                        class="stat-card__icon"
                        :symbolId="data.icon"
                    />

                    <p v-if="data.stat" class="stat-card__text">{{ statDisplay }}</p>
                </div>
                <p v-if="!data.stat" class="stat-card__disclaimer">{{ $t('Hang tight! No data to report yet.') }}</p>
            </div>
        </div>
        <NebulaIcon
            v-if="!isNebulaBreakpointsTabletPortrait"
            class="stat-card__icon stat-card__icon--mobile"
            :symbolId="data.icon"
        />
    </li>
</template>

<script>
import { NebulaIconButton, NebulaIcon, NebulaPopover } from '@discoveryedu/nebula-components';
import nebulaBreakpoints from '@/mixins/nebulaBreakpoints';

export default {
    name: 'StatCard',
    mixins: [
        nebulaBreakpoints,
    ],
    components: {
        NebulaIcon,
        NebulaPopover,
        NebulaIconButton,
    },
    props: {
        data: Object,
    },
    computed: {
        statDisplay() {
            const number = this.data.stat;
            if (number < 1000) {
                return number;
            }

            if (number > 1000000) {
                const millions = parseFloat((number / 1000000).toFixed(1));
                return `${millions}m`;
            }
            const thousands = parseFloat((number / 1000).toFixed(1));
            return `${thousands}k`;
        },
    },
};
</script>

<style lang="stylus">
.stat-card {
    align-items: center;
    background-color: $nebula-color-platform-white;
    display: flex;
    gap: $nebula-space-2x;
    justify-content: space-between;
    padding: $nebula-space-2x 0;
    position: relative;
    width: 100%;

    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        border-bottom: none;
        border-radius: $nebula-border-radius-small;
        box-shadow: $nebula-shadow-100;
        flex-direction: column;
        padding: $nebula-space-3x $nebula-space-2x;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid $nebula-color-platform-neutral-200;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__header {
        align-items: center;
        display: flex;
        gap: $nebula-space-half;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            gap: $nebula-space-1x;
            justify-content: center;
        }
    }

    &__title {
        font-size: $nebula-font-size-body-2;
        font-weight: 700;
        line-height: $nebula-font-line-height-heading;
        margin: 0;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            font-size: $nebula-font-size-card-title;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__metadata {
        display: flex;
        height: 100%;
        align-items: center;
        line-height: $nebula-font-line-height-default;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            align-self: center;
            gap: $nebula-space-1x;
            min-height: $nebula-space-8x;
        }
    }

    &__icon {
        display: block;
        fill: $nebula-color-platform-interface-400;
        height: $nebula-space-4x;
        width: $nebula-space-4x;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            width: $nebula-space-6x;
            height: $nebula-space-6x;
            margin: 0;
        }
    }

    &__text {
        color: $nebula-color-platform-neutral-900;
        font-size: $nebula-font-size-mobile-header-level-1;
        font-weight: 700;
        width: 100%;
        margin: 0;
        line-height: 1;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            font-size: $nebula-font-size-header-level-2;
        }
    }

    &__disclaimer {
        color: $nebula-color-platform-interface-800;
        font-size: $nebula-font-size-caption;
        margin: 0;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            text-align: center;
        }
    }

    &__popover {
        --nebula-popover-background-color: $nebula-color-platform-interface-1000;
        --nebula-popover-border-radius: $nebula-border-radius-default;
        --nebula-popover-border: none;
        --nebula-popover-close-button-icon-fill: $nebula-color-platform-white;
        --nebula-popover-font-size: $nebula-font-size-caption;
        --nebula-popover-text-color: $nebula-color-platform-white;

        &-content {
            display: flex;
            flex-direction: column;
            gap: var(--nebula-space-2x);
            max-width: 312px;
        }

        &-text {
            margin: 0;
        }

        &-link {
            color: var(--nebula-color-platform-interactive-400);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
