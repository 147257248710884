<template>
    <NebulaCard
        element="li"
        class="request-card"
    >
        <template #content>
            <div>
                <p
                    v-if="data.is_recommended"
                    class="request-card__recommended"
                >
                    Recommended
                </p>
                <div class="request-card__title-block">
                    <p class="request-card__type">{{ data.typeDisplay }}</p>
                    <p class="request-card__title">{{ data.title }}</p>
                </div>
                <div class="request-card__details">
                    <p class="request-card__detail-title">Details</p>
                    <DetailBlock :data="data" simple :items="['date', 'time', 'subject', 'grade']" />
                </div>
            </div>
            <NebulaButton
                class="request-card__button"
                icon-left="notes"
                :link="link"
                text="View Details"
                type="ghost"
                size="l"
            />
        </template>
    </NebulaCard>
</template>

<script>
import {
    NebulaButton,
    NebulaCard,
    // NebulaIcon,
} from '@discoveryedu/nebula-components';
import formatDate from '@/mixins/formatDate';
import subjectIconMap from '@/data/subjectIconMap';
import DetailBlock from '@/components/dashboard/DetailBlock.vue';

export default {
    name: 'RequestCard',
    components: {
        NebulaButton,
        NebulaCard,
        // NebulaIcon,
        DetailBlock,
    },
    data() {
        return {
            subjectIconMap,
        };
    },
    mixins: [formatDate],
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        link() {
            return `/request/${this.data.requestId}`;
        },
    },
};
</script>

<style lang='stylus'>
.request-card {
    --nebula-card-border-radius: $nebula-border-radius-small;
    --nebula-card-content-padding: $nebula-space-3x;
    --nebula-card-margin: 0;
    --nebula-card-max-width: auto;
    height: 100%;

    .nebula-card__content {
        container: cardContent / inline-size;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-block-end: $nebula-space-2x;
    }

    &__recommended {
        nebula-text-body-2();
        nebula-text-semibold();

        color: $nebula-color-platform-interface-800;
        text-align: center;
        margin: 0;
    }

    &__type {
        card-internal-title();
        font-weight: 700;
        margin: 0 0 $nebula-space-half;
    }

    &__title {
        nebula-text-card();

        margin: 0;
    }

    &__title-block {
        margin: 0 0 $nebula-space-2x;
    }

    &__details {
        margin-block-end: $nebula-space-1x;
    }

    &__detail-title {
        nebula-text-body-2();

        font-weight: bold;
        margin: 0 0 $nebula-space-1x;
    }

    &__detail-list {
        display: grid;
        gap: $nebula-space-1x $nebula-space-half;
        margin: $nebula-space-1x 0 0;
        padding: 0;
    }

    &__detail-item {
        list-style: none;
    }

    .detail-item__text {
        nebula-text-body-2();
    }

    &__button {
        justify-content: center;
        width: 100%;
    }
}

@container cardContent (min-width: 340px) {
    .request-card__detail-list {
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 1fr)
    }
}
</style>
